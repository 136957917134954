<template>
	<div class="card x-report-card">
		<!-- <p>ASD: {{ $route.meta }}</p> -->

		<ul class="nav nav-tabs align-items-center gap-x-2 mb-0 w-100" role="tabslits">
			<b-nav-item
				link-classes="px-1 justify-content-between w-100"
				class="x-tab-width"
				:to="{ name: `${$route.meta.category == 1 ? 'padrillos' : 'madrillas'}-con-posturas-sr` }"
				:active="$route.meta.tab == 1"
			>
				<span class="pr-75"> Con posturas </span>
				<b-badge variant="danger" pill>
					{{ postureCounter.with_posture }}
				</b-badge>
			</b-nav-item>
			<b-nav-item
				link-classes="px-1 justify-content-between w-100"
				class="x-tab-width"
				:to="{ name: `${$route.meta.category == 1 ? 'padrillos' : 'madrillas'}-sin-posturas-sr` }"
				:active="$route.meta.tab == 2"
			>
				<span class="pr-75"> Sin posturas </span>
				<b-badge variant="danger" pill>
					{{ postureCounter.without_posture }}
				</b-badge>
			</b-nav-item>
			<!-- <b-nav-item
				link-classes="px-1 justify-content-between w-100"
				class="x-tab-width"
				:to="{ name: `madrillas-recommended-posturas-sr` }"
				:active="$route.meta.tab == 3"
				v-if="$route.meta.category == 2"
			>
				<span class="pr-75"> Recomendadas </span>
				<b-badge variant="danger" pill>
					{{ postureCounter.recomended }}
				</b-badge>
			</b-nav-item> -->
		</ul>

		<div v-if="$route.meta.tab == 1" class="mt-2">
			<SharedSpecimenPostureReport
				@open-posture="manageOpenPostureByType"
				:category-id="categoryId"
				/>
				<!-- :year="year" -->
		</div>
		<div v-if="$route.meta.tab == 2" class="mt-2">
			<SharedSpecimenReport @open-posture="handleOpenAfterPostures" :category-id="categoryId" />
		</div>

		<!-- <div v-if="$route.meta.tab == 3 && $route.meta.category == 2" class="mt-2">
			<RecommendedMadrillas />
		</div> -->

		<SpecimenReportByPostureStatus
			v-if="modalPostureOpen"
			:postures="postures"
			:posture-status="currentPostureState"
			@hidden="modalPostureOpen = false"
			:specimen="currentSpecimen"
		/>
	</div>
</template>

<script>
import SpecimenReportByPostureStatus from "@/views/amg/encaste/posturas/modals/SpecimenReportByPostureStatus.vue";
import SharedSpecimenPostureReport from "@/views/amg/encaste/posturas/SharedSpecimenPostureReport.vue";
import SharedSpecimenReport from "@/views/amg/encaste/posturas/SharedSpecimenReport.vue";
import posturasService from "@/services/posturas.service";
import { mapState } from "vuex";
import RecommendedMadrillas from "./madrillas/RecommendedMadrillas.vue";
import { bus } from "@/main";

export default {
	name: "SpecimenPostureReportByCategory",
	components: {
		SpecimenReportByPostureStatus,
		SharedSpecimenPostureReport,
		SharedSpecimenReport,
		RecommendedMadrillas,
	},
	data: () => ({
		postures: [
			{
				specimen: {},
			},
		],
		postureStates: {
			slopes: "PENDIENTES",
			actives: "ACTIVAS",
			to_finalize: "POR FINALIZAR",
			finished: "FINALIZADAS",
			canceled: "ANULADAS",

			after: "ANTERIORES",
		},
		postureStatusId: {
			slopes: 1,
			actives: 2,
			to_finalize: 3,
			finished: 4,
			canceled: 5,

			after: "ANTERIORES",
		},
		currentPostureState: null,
		modalPostureOpen: false,
		postureCounter: {
			with_posture: 0,
			without_posture: 0,
		},
		currentSpecimen: {},
		tabId: 1,
	}),
	mounted() {
		this.tabId = this.$route.meta.tab;
	},
	async created() {
		await this.fetchPostureReportByCategory();
		bus.$on("reload-posturas-madrillas", async () => {
			await this.fetchPostureReportByCategory();
		});
		bus.$on("refreshCounters", async () =>{
			await this.fetchPostureReportByCategory();
		})
	},
	destroyed() {
		bus.$off("reload-posturas-madrillas");
		bus.$off("refreshCounters")
	},
	computed: {
		...mapState("encastePosturas", ["start_date","end_date"]),
		categoryId() {
			return this.$route.meta.category;
		},
	},
	methods: {
		async manageOpenPostureByType(detail, type) {
			this.isPreloading();
			this.currentPostureState = this.postureStates[type];
			this.currentSpecimen = detail.specimen;
			const postures = await posturasService.fetchPostureBySpecimenAndStatus({
				category_id: this.categoryId,
				specimen_id: this.currentSpecimen.id,
				status_id: this.postureStatusId[type],
				year_start: this.start_date,
				year_end: this.end_date,
			});
			this.postures = postures;
			this.modalPostureOpen = true;
			this.isPreloading(false);
		},
		handleOpenAfterPostures(detail) {
			this.currentPostureState = this.postureStates.after;
			this.currentSpecimen = detail.specimen;
			this.modalPostureOpen = true;
		},
		async fetchPostureReportByCategory() {
			const counters = await posturasService.fetchPostureCounterByParentsGender(this.categoryId, this.start_date, this.end_date);
			this.postureCounter = {
				with_posture: counters.with_postures,
				without_posture: counters.without_postures,
				recomended: counters.recomended,
			};
		},
	},
	watch: {
		async start_date(val) {
			await this.fetchPostureReportByCategory();
		},
		async categoryId() {
			await this.fetchPostureReportByCategory();
		},
		async tabId() {
			await this.fetchPostureReportByCategory();
		},
	},
};
</script>

<style lang="scss">
.x-report-card {
	padding: 2rem;
}
</style>
