<template>
	<b-table-simple responsive class="cst-table" v-if="!loading">
		<b-thead class="cst-head">
			<b-tr class="text-center">
				<b-th style="width: 5ch">#</b-th>
				<b-th>
					{{ categoryId == 1 ? "Padrillo" : "Madrilla" }}
				</b-th>
				<b-th> Pendientes </b-th>
				<b-th> Activas </b-th>
				<b-th> Por finalizar </b-th>
				<b-th> Finalizadas </b-th>
				<b-th> Anuladas </b-th>
			</b-tr>
		</b-thead>
		<TransitionGroup tag="tbody" role="table">
			<b-tr v-for="(posture, index) in postures" :key="posture.id" class="text-center">
				<b-th>{{ index + 1 }}</b-th>
				<b-th class="text-center py-50 px-1">
					<SpecimenPlate
						:specimen="{
							id: posture.specimen.id,
							plate: posture.specimen.plate,
							alias: posture.specimen.alias,
							thumb: posture.specimen.thumb,
						}"
					/>
				</b-th>
				<b-th class="text-center py-50 px-1">
					<span
						class="posture-count"
						:class="{ zero: posture.PENDIENTES == 0 }"
						@click="$emit('open-posture', posture, 'slopes')"
					>
						{{ posture.PENDIENTES }}
					</span>
				</b-th>
				<b-th class="text-center py-50 px-1">
					<span
						class="posture-count"
						:class="{ zero: posture.ACTIVAS == 0 }"
						@click="$emit('open-posture', posture, 'actives')"
					>
						{{ posture.ACTIVAS }}
					</span>
				</b-th>
				<b-th class="text-center py-50 px-1">
					<span
						class="posture-count"
						:class="{ zero: posture['POR FINALIZAR'] == 0 }"
						@click="$emit('open-posture', posture, 'to_finalize')"
					>
						{{ posture['POR FINALIZAR'] }}
					</span>
				</b-th>
				<b-th class="text-center py-50 px-1">
					<span
						class="posture-count"
						:class="{ zero: posture.FINALIZADAS == 0 }"
						@click="$emit('open-posture', posture, 'finished')"
					>
						{{ posture.FINALIZADAS }}
					</span>
				</b-th>
				<b-th class="text-center py-50 px-1">
					<span
						class="posture-count"
						:class="{ zero: posture.ANULADAS == 0 }"
						@click="$emit('open-posture', posture, 'canceled')"
					>
						{{ posture.ANULADAS }}
					</span>
				</b-th>
			</b-tr>
		</TransitionGroup>
	</b-table-simple>
	<b-skeleton-table
		v-else
		style="width: 100%"
		:rows="8"
		:columns="5"
		:table-props="{ bordered: true, striped: true }"
	/>
</template>

<script>
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue";
import posturasService from "@/services/posturas.service";
import { mapState } from "vuex";
export default {
	name: "SharedSpecimenPostureReport",
	components: {
		SpecimenPlate,
	},
	data: () => ({
		postures: [
			{
				specimen: {},
			},
		],
		loading: true,
	}),
	methods: {
		async fetchPostureByGender() {
			this.postures = await posturasService.fetchPostureByGenderAndYear(this.categoryId, this.start_date, this.end_date);
			this.loading = false;
		},
	},
	computed: {
		...mapState("encastePosturas", ["year","start_date","end_date"]),
		categoryId() {
			return this.$route.meta.category;
		},
	},
	mounted() {
		// this.categoryId = this.$route.meta.category;
	},
	async created() {
		await this.fetchPostureByGender();
	},
	watch: {
		async start_date() {
			await this.fetchPostureByGender();
		},
		async categoryId() {
			await this.fetchPostureByGender();
		},
	},
};
</script>

<style lang="scss">
.cst-table {
	max-height: 54vh;
	.cst-head {
		position: sticky;
		top: -1px;
		z-index: 2;
	}
}
.posture-count {
	padding: 0.3em 0.4em;
	position: relative;
	font-size: 1.2rem;
	&.zero {
		pointer-events: none;
	}
}
.posture-count:not(.zero) {
	color: var(--info);
	cursor: pointer;
	&::after {
		content: "";
		display: inline-block;
		height: 2px;
		left: 0.2em;
		right: 0.2em;
		bottom: 0.15em;
		position: absolute;
		background-color: currentColor;
	}
}
</style>
