<template>
	<div>
		<b-row class="mt-2">
			<b-col cols="12" class="d-flex justify-content-end paginator-label">
				<b-pagination
					@input="fetchSpecimensWithoutPostures"
					v-model="currentPage"
					:total-rows="this.total"
					:per-page="20"
					first-number
					last-number
					align="right"
					class="mb-0 mt-1 mt-sm-0"
					prev-class="prev-item"
					next-class="next-item"
				>
					<template #prev-text>
						<feather-icon icon="ChevronLeftIcon" size="18" />
					</template>
					<template #next-text>
						<feather-icon icon="ChevronRightIcon" size="18" />
					</template>
				</b-pagination>
			</b-col>
		</b-row>
		<b-row  class="my-2">
			<b-col cols="12" xl="7" lg="6" class="d-flex paginator-label">
				<p class="ml-1 ">
					Mostrando {{ this.from }} a {{ this.to }} de {{ this.total }} ejemplares disponibles
				</p>
			</b-col>
			<b-col cols="12" xl="5" lg="6" class="d-flex" style="gap: 12px;">
				<b-input-group>
					<b-input-group-prepend>
						<b-button class="btn-icon" variant="outline-secondary" disabled>
							<feather-icon icon="SearchIcon" />
						</b-button>
					</b-input-group-prepend>
					<b-form-input
						placeholder="Buscar placa o alias..."
						v-model="searchFilter"
						@keypress.enter="fetchSpecimensWithoutPostures"
					/>
				</b-input-group>
				<div class="d-flex" style="gap: 8px;">
					<b-button variant="primary" @click="fetchSpecimensWithoutPostures">Buscar</b-button>
					<b-button class="btn-icon" variant="outline-secondary">
						<feather-icon icon="RefreshCcwIcon" @click="clearFilters" />
					</b-button>
				</div>
			</b-col>
		</b-row>
		<b-table-simple responsive class="cst-table" v-if="!loading">
			<b-thead class="cst-head">
				<b-tr class="text-center">
					<b-th>#</b-th>
					<b-th class="text-center px-2 align-middle">
						{{ categoryId == 1 ? "Padrillo" : "Madrilla" }}
					</b-th>
					<b-th class="text-center px-2 align-middle"> Estado </b-th>
					<b-th class="text-center px-2 align-middle" v-if="categoryId == 2"> Edad </b-th>
					<b-th class="text-center px-2 align-middle" v-if="categoryId == 2"> # Huevos al aire </b-th>
					<!-- <b-th class="text-center px-2 align-middle" style="width: 34ch"> Posturas anteriores </b-th> -->
					<b-th class="text-center px-2 align-middle" style="width: 34ch"> Acciones </b-th>
				</b-tr>
			</b-thead>
			<TransitionGroup tag="tbody" role="table">
				<b-tr
					class="text-center row-table-posture"
					v-for="(posture, index) in postures"
					:key="posture.id"
					@mouseover="clearQueryMadrilla(posture)"
				>
					<b-th>{{ index + 1 }}</b-th>
					<b-th class="text-center py-50 px-2">
						<SpecimenPlate
							:specimen="{
								id: posture.specimen.id,
								plate: posture.specimen.plate,
								alias: posture.specimen.alias,
								thumb: posture.specimen.thumb,
								image: posture.specimen.image,
							}"
						/>
					</b-th>
					<b-th class="text-center py-50 px-2">
						<span>
							{{ posture.specimen.status }}
						</span>
					</b-th>
					<b-th class="text-center py-50 px-2" v-if="categoryId == 2">
						<SpecimenAge v-if="posture.specimen.dob" :age="posture.specimen.dob" />
						<p v-else>---</p>
					</b-th>
					<b-th class="text-center py-50 px-2" v-if="categoryId == 2">
						<p class="mb-0">{{ posture.eggs_in_the_air ? posture.eggs_in_the_air : 0 }}</p>
					</b-th>
					<b-th class="text-center py-50 px-2" >
						<feather-icon
							v-if="$route.meta.category == 2"
							v-b-tooltip.hover
							title="Agregar huevo al aire"
							icon="CrosshairIcon"
							size="24"
							class="cursor-pointer mr-50 text-warning"
							:class="styleForSpecimenBusy(posture.specimen, isCreatable)"
							@click="
								modalViewAddEggsToAir.show = true;
								modalViewAddEggsToAir.data = posture;
							"
						/>
						<feather-icon
							v-b-tooltip.hover
							title="Agregar postura"
							icon="PlusCircleIcon"
							size="24"
							class="cursor-pointer text-primary"
							:class="styleForSpecimenBusy(posture.specimen, isCreatable)"
							@click="$route.meta.category == 1 ? handleCreateNewPostureForPad(posture.specimen):handleCreateNewPostureForMad(posture.specimen)"
						/>
					</b-th>
				</b-tr>
			</TransitionGroup>
			<AddPosture
				v-if="openModal"
				fromGrid
				:madrillaController="categoryId === 2"
				:placa="currentSpecimen.plate"
				@hidden="openModal = false"
				@refresh="reset"
			/>
			<AssignMadrillasModal
				:show="assignMadrillas.show"
				:info="assignMadrillas.info"
				fromGrid
				@closing="assignMadrillas.show = false"
				@refresh="fetchSpecimensWithoutPostures"
			/>
		</b-table-simple>
		<b-skeleton-table
			v-else
			style="width: 100%"
			:rows="8"
			:columns="5"
			:table-props="{ bordered: true, striped: true }"
		/>
		
		<b-modal
			v-model="modalViewAddEggsToAir.show"
			:title="modalViewAddEggsToAir.title"
			hide-footer
			hide-header
			centered
			size="xs"
		>
			<h6 class="text-center mb-0">Huevos al aire</h6>
			<!-- <vue-number-input
				v-model="dead.postura.quantity"
				:min="1"
				:max="dead.postura.stock"
				inline
				controls
				style="max-width: 150px; margin-top: 5px"
				class="text-center"
				@change="onUpdate($event, index)"
			/> -->
			<div class="mt-1 mb-1 text-center w-100">
				<vue-number-input
					v-model="modalViewAddEggsToAir.eggs"
					:min="1"
					:max="10"
					inline
					controls
					center
					style="max-width: 150px; margin-top: 5px"
					class="text-center"
				/>
			</div>
			<button class="w-100 btn btn-primary" @click="addEggTheAir" type="button">Agregar</button>
		</b-modal>
	</div>
</template>

<script>
import { mapActions } from "vuex";
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue";
import SpecimenAge from "@/components/specimens/SpecimenAge.vue";
import AddPosture from "@/views/amg/encaste/posturas/modals/AddPostura.vue";
import posturasService from "@/services/posturas.service";
import EggService from "@/services/egg.service";
import AssignMadrillasModal from './modals/AssignMadrillasModal.vue'

export default {
	name: "SharedSpecimenReport",
	components: {
		SpecimenPlate,
		SpecimenAge,
		AddPosture,
		AssignMadrillasModal
	},
	props: {
		categoryId: {
			type: Number,
			default: 1,
		},
		year: [String, Number],
	},
	data: () => ({
		openModal: false,
		postures: [
			{
				specimen: {},
			},
		],
		currentSpecimen: null,
		loading: true,
		modalViewAddEggsToAir: {
			show: false,
			title: "Agregar huevo al aire",
			eggs: 1,
			data: null,
		},
		currentPage: null,
		from: null,
		total: null,
		to: null,
		perpage: null,
		padActive: null,
		searchFilter: null,
		assignMadrillas: { show: false, info: {} },
	}),
	methods: {
		...mapActions("notifications", ["getNotifications", "getCount"]),
		handleCreateNewPostureForMad(specimen) {
			this.currentSpecimen = specimen;
			this.isPreloading(true);
			this.openModal = true;
		},
		handleCreateNewPostureForPad(specimen) {
			this.assignMadrillas.info = specimen
			this.assignMadrillas.show = !this.assignMadrillas.show
		},
		async fetchSpecimensWithoutPostures() {
			this.loading = true;
			const { data } = await posturasService.fetchSpecimensWithoutPostures(
				{
					category_id: this.categoryId,
					status_id: this.padActive,
					page: this.currentPage,
					perpage: 20,
					search:this.searchFilter,
				},
			);
			this.currentPage = data.current_page
			this.from = data.from
			this.total = data.total
			this.to = data.to
			this.postures = data.data.map((it) => {

				return {
					...it,
					eggs_in_the_air: it.eggs_in_the_air || 0,
				};
			});
			this.loading = false;
		},
		clearFilters() {
			this.searchFilter = ""
			this.fetchSpecimensWithoutPostures()
		},
		clearQueryMadrilla(postura) {
			if (this.$route.query.madrilla_id == postura.specimen.id) {
				setTimeout(() => {
					// this.$router.replace({ query: null });
				}, 500);
			}
		},
		async addEggTheAir() {
			// this.modalViewAddEggsToAir.show = true;
			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;
			this.isPreloading();
			const { message } = await EggService.insertNotification({
				madrilla_id: this.modalViewAddEggsToAir.data.specimen.id,
				quantity: this.modalViewAddEggsToAir.eggs,
			});
			for (let i = 0; i < this.postures.length; i++) {
				if (this.modalViewAddEggsToAir.data.specimen.id == this.postures[i].specimen.id) {
					this.postures[i].eggs_in_the_air =
						parseInt(this.postures[i].eggs_in_the_air) + this.modalViewAddEggsToAir.eggs;
					break;
				}
			}
			this.isPreloading(false);
			this.showToast("success", "top-right", "Huevos al aire", "SuccessIcon", message);
			this.modalViewAddEggsToAir = {
				show: false,
				eggs: 1,
				data: null,
			};
			bus.$emit("reload-posturas-madrillas");
			// Refrescar notificaciones // Se esta usando el socket
			// await this.getCount();
			// await this.getNotifications({ page: 1, perpage: 10 });
		},
		async reset() {
			// await this.fetchSpecimensWithoutPostures();
			// await this.getCount();
			// await this.getNotifications({ page: 1, perpage: 10 });
		},
	},
	computed: {
		styleForSpecimenBusy: () => (specimen, user) => ({
			"pointer-events-none": specimen.status !== "ACTIVO" || !user,
			"text-muted": specimen.status !== "ACTIVO" || !user,
		}),
		isCreatable() {
			return this.currentUser.id == 1;
		},
	},
	async created() {
		await this.fetchSpecimensWithoutPostures();
		const vm = this;
		this.$root.$on("reloader-posturas-list", async function () {
			await vm.fetchSpecimensWithoutPostures();
		});
	},
	destroyed() {
		this.$root.$off("reloader-posturas-list");
	},
	watch: {
		async categoryId() {
			await this.fetchSpecimensWithoutPostures();
		},
	},
};
</script>

<style lang="scss">
.row-table-posture {
	transition: 1s all ease-in-out;
}
.bg-select-id-madrilla {
	background: rgba($color: #000000, $alpha: 0.1);
	cursor: pointer;
}
.paginator-label{
	@media (max-width: 992px) {
		justify-content: center !important;
	}
}
</style>
