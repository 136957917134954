<template>
	<b-modal
		lazy
		ref="specimen-report-modal"
		hide-footer
		size="lg"
		header-class="py-1"
		v-on="$listeners"
		id="specimen-report-modal"
	>
		<template #modal-title>
			<span>
				{{ specimen.alias ? specimen.alias : "Sin alias" }} |
				{{ specimen.plate ? specimen.plate : "Sin placa" }}
			</span>
		</template>
		<section class="py-1 px-50">
			<h5 class="pb-1">
				<span class="text-muted">POSTURAS {{ postureStatus }}: </span>
				<span class="text-warning">{{ postures.length }}</span>
			</h5>
			<b-table-simple class="sp-simple-table" responsive>
				<b-thead>
					<b-tr>
						<b-th class="text-center px-1"> # </b-th>
						<b-th class="text-center px-1" style="width: 34ch"> Madrilla </b-th>
						<b-th class="text-center px-1"> Codigo </b-th>
						<b-th class="text-center px-1"> Fecha </b-th>
					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr v-for="(posture, index) in postures" :key="posture.id">
						<b-th class="text-center px-1 py-50">
							{{ index + 1 }}
						</b-th>
						<b-th class="text-center px-1 py-50">
							<SpecimenPlate
								:specimen="{
									id: posture.specimen.id,
									plate: posture.specimen.plate,
									alias: posture.specimen.alias,
									thumb: posture.specimen.thumb,
								}"
							/>
						</b-th>
						<b-th class="text-center px-1 py-50">
							{{ posture.code }}
						</b-th>
						<b-th class="text-center px-1 py-50">
							{{ posture.start_date | myGlobalDayShort }}
						</b-th>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</section>
	</b-modal>
</template>

<script>
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue";
export default {
	name: "SpecimenReportByPosturaStatus",
	components: {
		SpecimenPlate,
	},
	props: {
		postures: {
			type: [Array, Object],
			default: () => [],
		},
		postureStatus: {
			type: String,
		},
		specimen: {
			type: Object,
			default: () => ({
				id: null,
				plate: null,
				alias: null,
			}),
		},
	},
	mounted() {
		this.$refs["specimen-report-modal"].toggle();
	},
};
</script>

<style lang="scss">
#specimen-report-modal .modal-content {
	border: 2px solid #d5d2de;
	.dark-layout & {
		border-color: #3b4253;
	}
}
.sp-simple-table {
	max-height: 54vh;
	overflow-y: auto;
	thead {
		position: sticky;
		top: -1px;
		z-index: 2;
	}
	&::-webkit-scrollbar {
		background: #f3f2f7;
		width: 5px;
		height: 5px;
		&-thumb {
			background: #7367f0;
			border-radius: 3px;
			border: 1px solid #f3f2f7;
		}
	}
	.dark-layout &::-webkit-scrollbar {
		background-color: #3b4253;
		&-thumb {
			border-color: #3b4253;
		}
	}
}
</style>
